import BaseModel from "./BaseModel";
import PlaceCity from "./PlaceCity";
import PlaceCountry from "./PlaceCountry";
import PlaceRegion from "./PlaceRegion";
import PlaceDistrict from "./PlaceDistrict";
import PlaceTouristRegion from "./PlaceTouristRegion";

export default class AccommodationLocation extends BaseModel {
    private _street: string = '';
    private _street_number: string = '';
    private _city: PlaceCity | null = null;
    private _country: PlaceCountry | null = null;
    private _region: PlaceRegion | null = null;
    private _district: PlaceDistrict | null = null;
    private _tourist_region: PlaceTouristRegion | null = null;
    private _latitude: number | null = null;
    private _longitude: number | null = null;
    private _id_city: number | null = null;
    private _id_tourist_region: number | null = null


    get street(): string {
        return this._street;
    }

    set street(value: string) {
        this._street = value;
    }

    get street_number(): string {
        return this._street_number;
    }

    set street_number(value: string) {
        this._street_number = value;
    }

    get city(): PlaceCity | null {
        return this._city;
    }

    set city(value: PlaceCity | null) {
        this._city = value;
    }

    get country(): PlaceCountry | null {
        return this._country;
    }

    set country(value: PlaceCountry | null) {
        this._country = value;
    }

    get region(): PlaceRegion | null {
        return this._region;
    }

    set region(value: PlaceRegion | null) {
        this._region = value;
    }

    get district(): PlaceDistrict | null {
        return this._district;
    }

    set district(value: PlaceDistrict | null) {
        this._district = value;
    }

    get latitude(): number | null {
        return this._latitude;
    }

    set latitude(value: number | null) {
        this._latitude = value;
    }

    get longitude(): number | null {
        return this._longitude;
    }

    set longitude(value: number | null) {
        this._longitude = value;
    }

    get id_city(): number | null {
        return this._id_city;
    }

    set id_city(value: number | null) {
        this._id_city = value;
    }

    get id_tourist_region(): number | null {
        return this._id_tourist_region;
    }

    set id_tourist_region(value: number | null) {
        this._id_tourist_region = value;
    }

    get tourist_region(): PlaceTouristRegion | null {
        return this._tourist_region;
    }

    set tourist_region(value: PlaceTouristRegion | null) {
        this._tourist_region = value;
    }
}

import BaseModel from "./BaseModel";

export class AccommodationPriceAddition extends BaseModel {
    private _id: number = -1;
    private _curr_prices: { [currencyCode: string]: { from: number, to: number } } = {}
    private _code: string = '';
    private _note: string = '';
    private _price_for: string = '';


    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get curr_prices(): { [p: string]: { from: number; to: number } } {
        return this._curr_prices;
    }

    set curr_prices(value: { [p: string]: { from: number; to: number } }) {
        this._curr_prices = value;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get price_for(): string {
        return this._price_for;
    }

    set price_for(value: string) {
        this._price_for = value;
    }


    get note(): string {
        return this._note;
    }

    set note(value: string) {
        this._note = value;
    }
}

import BaseModel from "./BaseModel";
import Image from "./Image";
import {formatTime, stripTags, timeSince} from "../functions";
import Client from "./Client";
import AccommodationContact from "./AccommodationContact";
import AccommodationUnit from "./AccommodationUnit";
import AccommodationReview from "./AccommodationReview";
import PlaceAttraction from "./PlaceAttraction";
import AccommodationLocation from "./AccommodationLocation";
import AccommodationUnitPriceLevel from "./AccommodationUnitPriceLevel";
import {AccommodationPriceAddition} from "./AccommodationPriceAddition";

export default class Accommodation extends BaseModel {

    private _id: number = -1;
    private _small_description: null;
    private _description: null | string;
    private _hidden: boolean = false;
    private _link: string;
    private _old: boolean = false;
    private _removed: boolean = false;
    private _verified: boolean = false;
    private _reserve_whole_object: boolean = false;
    private _price: number = 0;
    private _review_count: number = 0;
    private _review_rating: number = 0;
    private _parking_places_count: number = 0;
    private _parking_distance: string = '';
    private _parking_possibilities: Array<string> = [];
    private _translations: {[lang:number]: {[type: string]: {[typeId: string]: string}}} = {};
    private _equipment: Array<{ [k: string]: Array<string> }> | {} = {};
    private _review_rating_counts: Array<{ [k: string|number]: number }> | {} = {};
    private _smoking_policy: string = "";
    private _pet_policy: string = "";
    private _owner_living_policy: string = "";
    private _virtual_presentation: string = "";
    private _video_link: string = "";
    private _title: string = "";
    private _type: string = "";
    private _category: string = "";
    private _time_arrival_from: string = "";
    private _time_arrival_to: string = "";
    private _time_leave_from: string = "";

    private _main_currency: string = "";
    private _time_leave_to: string = "";
    private _storno_conditions: string = "";
    private _owner_note: string = "";
    private _max_hosts_capacity: number = 1;
    private _price_from: number = 9999;
    private _has_last_minute: boolean = false;
    private _is_top: boolean = false;

    private _removed_at: Date | undefined;
    private _verification_date: Date | null;
    private _location: AccommodationLocation = new AccommodationLocation();
    private _default_price_level: AccommodationUnitPriceLevel = new AccommodationUnitPriceLevel();
    private _default_weekend_price_level: AccommodationUnitPriceLevel = new AccommodationUnitPriceLevel();
    private _images: Image[] = [];
    private _videos: Image[] = [];
    private _contacts: AccommodationContact[] = [];
    private _units: AccommodationUnit[] = [];
    private _price_additions: AccommodationPriceAddition[] = [];
    private _last_minutes: AccommodationUnitPriceLevel[] = [];
    private _reviews: AccommodationReview[] = [];
    private _owner: Client = new Client();
    private _attractions: PlaceAttraction[] = [];
    private _created_at: Date;
    private _updated_at: Date;
    private _reservation_stats: { check_in: number; check_out: number, ended_trips: number } | null = null;
    private _rooms_count_stat: {
        bedroom: { count: number },
        bathroom: { count: number },
        'living-room': { count: number },
        kitchen: { count: number }
    } = {
        bedroom: {count: 0},
        bathroom: {count: 0},
        kitchen: {count: 0},
        'living-room': {count: 0},
    };


    public isNew(): boolean {
        return !(this.id > 0);
    }

    public hasImage(includeHidden = true) {
        return includeHidden ? this.images.length > 0 : this.notHiddenImages.length > 0;
    }

    public getTopImage() {
        return this.notHiddenImages.length > 0 ? this.notHiddenImages[0] : this.images[0];
    }

    public getLinkWithSlash(locale: string | undefined, webCountry: string | undefined): string {
        return `/${locale ? (locale + (webCountry ? `-${webCountry}/` : '/')) : ''}${this.link || ""}`;
    }

    public getGeneratedDescription(): string {
        return "";
    }

    public isInFavorites(favoriteItems: any) {
        return favoriteItems.some((fitem: Object) => {
            const fProduct = (new Accommodation()).fromObject(fitem) as Accommodation;
            return fProduct.id === this.id && fProduct.id > 0 && this.id > 0;
        });
    }

    get imagesIncludingUnits(): Image[] {
        let images = this.notHiddenImages ? this.notHiddenImages : [];
        this.units.forEach((unit) => {
            images = images.concat(unit.notHiddenImages);
            unit.rooms.forEach((room) => {
                images = images.concat(room.notHiddenImages);
            })
        })
        return images;
    }

    public fromObject(jsonObj: object): BaseModel {
        return super.fromObject(jsonObj, {
            images: (item => {
                return (new Image()).fromObject(item);
            }),
            videos: (item => {
                return (new Image()).fromObject(item);
            }),
            contacts: (item => {
                return (new AccommodationContact()).fromObject(item);
            }),
            units: (item => {
                return (new AccommodationUnit()).fromObject(item);
            }),
            reviews: (item => {
                return (new AccommodationReview()).fromObject(item);
            }),
            attractions: (item => {
                return (new PlaceAttraction()).fromObject(item);
            }),
            last_minutes: (item => {
                return (new AccommodationUnitPriceLevel()).fromObject(item);
            }),
            price_additions: (item => {
                return (new AccommodationPriceAddition()).fromObject(item);
            })
        });
    }

    getTotalGuestsCount(): number {

        const rCount = function (cv) {
            return cv.beds.reduce((pv_c, cv_c) => {
                return pv_c + (cv_c.count * ((cv_c.type === 'double' || cv_c.type === 'bunk') ? 2 : 1));
            }, 0)
        }

        if (this.units && this.units.length > 0) {
            return this.units.reduce((pv, cv) => {

                return pv + cv.rooms.reduce((pv_r, cv_r) => {
                    return pv_r + (rCount(cv_r) * (cv.duplicate_count > 0 ? cv.duplicate_count : 1));
                }, 0)

            }, 0);
        } else {
            return this.max_hosts_capacity;
        }
    }

    getTotalUnitsCount(): number {
        return this.units && this.units.length > 0
            ? this.units.reduce((pVal, unit) => {
                return pVal += (unit.duplicate_count > 0 ? unit.duplicate_count : 1)
            }, 0)
            : 0;
    }

    getTotalRoomsCount(typeOfRoom: string | null = null): number {
        if (typeOfRoom) {
            if (this.rooms_count_stat[typeOfRoom] !== undefined) {
                return this.rooms_count_stat[typeOfRoom].count
            } else {
                return 0;
            }
        } else {
            return Object.keys(this.rooms_count_stat).reduce((currentCount, key) => {
                return currentCount + this.rooms_count_stat[key].count;
            }, 0)
        }
    }

    get formatPrice(): string {
        return this.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    get clearDescriptionLength(): number {
        if (this.description) {
            return this.description.replace(/<\/?[^>]+(>|$)/g, "").length;
        } else {
            return 0;
        }
    }


    get small_description(): null {
        return this._small_description;
    }

    set small_description(value: null) {
        this._small_description = value;
    }

    public getFormatedCreatedAt(format: string): string {
        return formatTime(this.created_at, format);
    }

    public getFormatedUpdatedAt(format: string): string {
        return formatTime(this.updated_at, format);
    }

    public getTextCreatedAtString(): string {
        return timeSince(new Date(this._created_at));
    }

    get created_at(): Date {
        return this._created_at;
    }

    set created_at(value: Date) {
        this._created_at = value;
    }

    get stripDescription(): string {
        return this.description && this.description.length > 0 ? stripTags(this.description, true) : "";
    }

    get description(): string | null {
        return this._description;
    }

    set description(value: string | null) {
        this._description = value;
    }

    get hidden(): boolean {
        return this._hidden;
    }

    set hidden(value: boolean) {
        this._hidden = value;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get link(): string {
        return this._link;
    }

    set link(value: string) {
        this._link = value;
    }

    get old(): boolean {
        return this._old;
    }

    set old(value: boolean) {
        this._old = value;
    }

    get removed(): boolean {
        return this._removed;
    }

    set removed(value: boolean) {
        this._removed = value;
    }

    get removed_at(): Date | undefined {
        return this._removed_at;
    }

    set removed_at(value: Date | undefined) {
        this._removed_at = value;
    }

    get price(): number {
        return this._price;
    }

    set price(value: number) {
        this._price = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get updated_at(): Date {
        return this._updated_at;
    }

    set updated_at(value: Date) {
        this._updated_at = value;
    }

    get notHiddenImages(): Image[] {
        return this.images.filter(i => i.hidden === false);
    }

    get images(): Image[] {
        return this._images;
    }

    set images(value: Image[]) {
        this._images = value;
    }

    get videos(): Image[] {
        return this._videos;
    }

    set videos(value: Image[]) {
        this._videos = value;
    }

    get contacts(): AccommodationContact[] {
        return this._contacts;
    }

    set contacts(value: AccommodationContact[]) {
        this._contacts = value;
    }

    get units(): AccommodationUnit[] {
        return this._units;
    }

    set units(value: AccommodationUnit[]) {
        this._units = value;
    }

    get owner(): Client {
        return this._owner;
    }

    set owner(value: Client) {
        this._owner = value;
    }


    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }


    get category(): string {
        return this._category;
    }

    set category(value: string) {
        this._category = value;
    }


    get time_arrival_from(): string {
        return this._time_arrival_from;
    }

    set time_arrival_from(value: string) {
        this._time_arrival_from = value;
    }

    get time_arrival_to(): string {
        return this._time_arrival_to;
    }

    set time_arrival_to(value: string) {
        this._time_arrival_to = value;
    }

    get time_leave_from(): string {
        return this._time_leave_from;
    }

    set time_leave_from(value: string) {
        this._time_leave_from = value;
    }

    get time_leave_to(): string {
        return this._time_leave_to;
    }

    set time_leave_to(value: string) {
        this._time_leave_to = value;
    }


    get parking_places_count(): number {
        return this._parking_places_count;
    }

    set parking_places_count(value: number) {
        this._parking_places_count = value;
    }

    get parking_possibilities(): Array<string> {

        return this._parking_possibilities;
    }

    set parking_possibilities(value: Array<string>) {
        this._parking_possibilities = value;
    }

    get equipment(): Array<{ [k: string]: Array<string> }> {
        return this._equipment;
    }

    set equipment(value: Array<{ [p: string]: Array<string> }>) {
        this._equipment = value;
    }

    get parking_distance(): string {
        return this._parking_distance;
    }

    set parking_distance(value: string) {
        this._parking_distance = value;
    }

    get smoking_policy(): string {
        return this._smoking_policy;
    }

    set smoking_policy(value: string) {
        this._smoking_policy = value;
    }

    get pet_policy(): string {
        return this._pet_policy;
    }

    set pet_policy(value: string) {
        this._pet_policy = value;
    }

    get max_hosts_capacity(): number {
        return this._max_hosts_capacity;
    }

    set max_hosts_capacity(value: number) {
        this._max_hosts_capacity = value;
    }

    get owner_living_policy(): string {
        return this._owner_living_policy;
    }

    set owner_living_policy(value: string) {
        this._owner_living_policy = value;
    }

    get verified(): boolean {
        return this._verified;
    }

    set verified(value: boolean) {
        this._verified = value;
    }

    get verification_date(): Date | null {
        return this._verification_date;
    }

    set verification_date(value: Date | null) {
        this._verification_date = value;
    }


    get reserve_whole_object(): boolean {
        return this._reserve_whole_object;
    }

    set reserve_whole_object(value: boolean) {
        this._reserve_whole_object = value;
    }

    get review_count(): number {
        return Number(this._review_count > 0 ? this._review_count : 0);
    }

    set review_count(value: number) {
        this._review_count = value;
    }

    get review_rating(): number {
        return Math.round(Number(this._review_rating) * 10) / 10;
    }

    set review_rating(value: number) {
        this._review_rating = value;
    }

    get reviews(): AccommodationReview[] {
        return this._reviews;
    }

    set reviews(value: AccommodationReview[]) {
        this._reviews = value;
    }

    get attractions(): PlaceAttraction[] {
        return this._attractions;
    }

    set attractions(value: PlaceAttraction[]) {
        this._attractions = value;
    }

    get reservation_stats(): { check_in: number; check_out: number; ended_trips: number } | null {
        return this._reservation_stats;
    }

    set reservation_stats(value: { check_in: number; check_out: number; ended_trips: number } | null) {
        this._reservation_stats = value;
    }

    get location(): AccommodationLocation {
        return this._location;
    }

    set location(value: AccommodationLocation) {
        this._location = value;
    }

    get rooms_count_stat(): {
        bedroom: { count: number };
        bathroom: { count: number };
        "living-room": { count: number };
        kitchen: { count: number }
    } {
        return this._rooms_count_stat;
    }

    set rooms_count_stat(value: {
        bedroom: { count: number };
        bathroom: { count: number };
        "living-room": { count: number };
        kitchen: { count: number }
    }) {
        this._rooms_count_stat = value;
    }

    get has_last_minute(): boolean {
        return this._has_last_minute;
    }

    set has_last_minute(value: boolean) {
        this._has_last_minute = value;
    }

    get last_minutes(): AccommodationUnitPriceLevel[] {
        return this._last_minutes;
    }

    set last_minutes(value: AccommodationUnitPriceLevel[]) {
        this._last_minutes = value;
    }

    get is_top(): boolean {
        return this._is_top;
    }

    set is_top(value: boolean) {
        this._is_top = value;
    }

    get price_from(): number {
        return this._price_from;
    }

    set price_from(value: number) {
        this._price_from = value;
    }

    get storno_conditions(): string {
        return this._storno_conditions;
    }

    set storno_conditions(value: string) {
        this._storno_conditions = value;
    }

    get owner_note(): string {
        return this._owner_note;
    }

    set owner_note(value: string) {
        this._owner_note = value;
    }

    get default_price_level(): AccommodationUnitPriceLevel {
        return this._default_price_level;
    }

    set default_price_level(value: AccommodationUnitPriceLevel) {
        this._default_price_level = value;
    }


    get main_currency(): string {
        return this._main_currency;
    }

    set main_currency(value: string) {
        this._main_currency = value;
    }


    get virtual_presentation(): string {
        return this._virtual_presentation;
    }

    set virtual_presentation(value: string) {
        this._virtual_presentation = value;
    }

    get video_link(): string {
        return this._video_link;
    }

    set video_link(value: string) {
        this._video_link = value;
    }

    get price_additions(): AccommodationPriceAddition[] {
        return this._price_additions;
    }

    set price_additions(value: AccommodationPriceAddition[]) {
        this._price_additions = value;
    }

    get review_rating_counts(): Array<{ [p: string]: number; [p: number]: number }> | {} {
        return this._review_rating_counts;
    }

    set review_rating_counts(value: Array<{ [p: string]: number; [p: number]: number }> | {}) {
        this._review_rating_counts = value;
    }

    get reviewNumeredRating(){
        const stars = {};
        for (let i = 1; i <= 5; i++) {
            stars[i] = this.review_rating_counts[i] ? this.review_rating_counts[i] : 0;
        }
        return stars;
    }

    get translations(): { [p: number]: { [p: string]: { [p: string]: string } } } {
        return typeof this._translations === 'object' && Object.keys(this._translations).length > 0 ? this._translations : {};
    }

    set translations(value: { [p: number]: { [p: string]: { [p: string]: string } } }) {
        this._translations = value;
    }
}

import BaseModel from "./BaseModel";

export default class AccommodationContact extends BaseModel {

    private _contact_person: string = "";
    private _phones: Array<{ number: string, country: string }> = [];
    private _email: string = "";
    private _whatsapp: string = "";

    get contact_person(): string {
        return this._contact_person;
    }

    set contact_person(value: string) {
        this._contact_person = value;
    }

    get phones(): Array<{ number: string; country: string }> {
        return this._phones;
    }

    set phones(value: Array<{ number: string; country: string }>) {
        this._phones = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }


    get whatsapp(): string {
        return this._whatsapp;
    }

    set whatsapp(value: string) {
        this._whatsapp = value;
    }

    get hasWhatsappNumber(): boolean {
        return (typeof this._whatsapp === "string" && this._whatsapp.length > 4);
    }
}
